import axios from 'axios';

export const orderDataFake = {
  orderId: 1,
  ticketNumber: '',
  restaurant: {
    name: '',
  },
  paymentMean: '',
  consommationMode: 2,
  expectedDate: Math.floor(new Date().getTime() / 1000),
  channelId: 1,
  created_at: Math.floor(new Date().getTime() / 1000),
};

export const getXmlTicketNameWithSolde = async (): Promise<string> => {
  const { data: ticket } = await axios.get('/ticket.xml');
  return ticket;
};
