import React from 'react';
import { Switch, Route } from 'react-router'; /* react-router v4/v5 */
import { ConnectedRouter } from 'connected-react-router';
import { History } from 'history';
import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';

import allMessages, { SupportedLanguage } from 'translation';
import Login from 'pages/Login';
import App from 'pages/Home';
import Config from 'pages/Config';
import Badge from 'pages/Badge';
import Reload from 'pages/Reload';
import CustomAmount from 'pages/CustomAmount';
import PaymentInProcess from 'pages/PaymentInProcess';
import PaymentValidated from 'pages/PaymentValidated';
import GenericError from 'pages/GenericError';
import PaymentError from 'pages/PaymentError';
import PaymentCheck from 'pages/PaymentCheck';
import Modal from 'components/Modal';
import HardwareGate from 'components/HardwareGate';
import CustomerGate from 'components/CustomerGate';
import AuthGate from 'components/AuthGate';
import { getAppLocale } from 'redux/app/app.selectors';
import { RootState } from 'redux/store';
import TransactionBacklogGate from 'components/TransactionBacklogGate';
import Maintenance from 'pages/Maintenance';
import PaymentWaiting from 'pages/PaymentWaiting';

interface Props {
  history: History;
  locale: SupportedLanguage;
}

const Router: React.FunctionComponent<Props> = ({ history, locale }) => (
  <ConnectedRouter history={history}>
    <IntlProvider locale={locale} messages={allMessages[locale]}>
      <Switch>
        <HardwareGate component={Maintenance}>
          <Route exact path="/login" component={Login} />
          <AuthGate redirect="/login">
            <Route exact path="/config" component={Config} />
            <TransactionBacklogGate component={Maintenance}>
              <Route exact path="/" component={App} />
              <Route exact path="/badge" component={Badge} />
              <CustomerGate redirect="/">
                <Route exact path="/reload" component={Reload} />
                <Route exact path="/reload-custom" component={CustomAmount} />
                <Route
                  exact
                  path="/payment/pending"
                  component={PaymentInProcess}
                />
                <Route
                  exact
                  path="/payment/waiting"
                  component={PaymentWaiting}
                />
                <Route
                  exact
                  path="/payment/hardware"
                  component={PaymentCheck}
                />
                <Route exact path="/payment/error" component={PaymentError} />
                <Route
                  exact
                  path="/payment/validated"
                  component={PaymentValidated}
                />
                <Route path="/error/:error" component={GenericError} />
              </CustomerGate>
            </TransactionBacklogGate>
          </AuthGate>
        </HardwareGate>
      </Switch>
      <Modal />
    </IntlProvider>
  </ConnectedRouter>
);

const mapStateToProps = (state: RootState): { locale: SupportedLanguage } => ({
  locale: getAppLocale(state),
});
export default connect(mapStateToProps)(Router);
