import {
  put,
  call,
  select,
  take,
  delay,
  race,
  takeLatest,
} from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { Action } from 'redux';
import { push } from 'connected-react-router';
import neptingService, {
  NeptingSuccessfullTransactionResponse,
} from 'services/Nepting/nepting.service';

import { getReloadAmount } from 'redux/reload/reload.selectors';
import { NeptingTransactionError } from 'services/Nepting/nepting.types';
import { getNeptingMerchantId } from 'redux/configuration/configuration.selectors';
import simpleLogService from 'services/SimpleLog';
import {
  displayNeptingErrorMessage,
  neptingPaymentSequenceEnded,
  startNeptingPayment,
  startNeptingInctivityWatcher,
} from './nepting.actions';

export const INACTIVITY_TIMEOUT = 1000 * 10;

function* paymentHandler(): Saga {
  const amount: ReturnType<typeof getReloadAmount> = yield select(
    getReloadAmount,
  );

  const neptingMerchantId: ReturnType<typeof getNeptingMerchantId> = yield select(
    getNeptingMerchantId,
  );

  if (amount === undefined || neptingMerchantId === null) {
    yield put<Action>(neptingPaymentSequenceEnded({ isSucess: false }));
    return;
  }

  if (!neptingService.initialized) {
    yield put<Action>(neptingPaymentSequenceEnded({ isSucess: false }));
  }

  yield put(push('/payment/pending'));

  try {
    simpleLogService.info('[Saga][nepting][paymentHandler] startTransaction');
    const ticket: NeptingSuccessfullTransactionResponse = yield call(() =>
      neptingService.startTransaction({
        cents: amount,
      }),
    );

    yield put<Action>(
      neptingPaymentSequenceEnded({
        isSucess: true,
        ticket: ticket.customerTicket,
      }),
    );
  } catch (e) {
    simpleLogService.info('[Saga][nepting][paymentHandler] error');

    const error = e as NeptingTransactionError;
    yield put<Action>(displayNeptingErrorMessage(error.message ?? null));
    yield put(push('/payment/error'));
    yield put<Action>(startNeptingInctivityWatcher());
  }
}

function* neptingInactivityWatcher(): Saga {
  const { inactive } = yield race({
    startPayment: take(getType(startNeptingPayment)),
    inactive: delay(INACTIVITY_TIMEOUT),
  });

  if (inactive) {
    yield put<Action>(neptingPaymentSequenceEnded({ isSucess: false }));
  }
}

export function* watchNeptingActions(): Saga {
  yield takeLatest(getType(startNeptingPayment), paymentHandler);
  yield takeLatest(
    getType(startNeptingInctivityWatcher),
    neptingInactivityWatcher,
  );
}
