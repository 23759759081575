import { fork, delay, select, call, put } from 'redux-saga/effects';
import {
  validateTransactionDraft,
  cancelTransactionDraft,
} from 'services/innovorder';
import { getToken } from 'redux/app/app.selectors';
import { markTransactionAsProcessed } from './transactionBacklog.actions';
import { getTransactionBacklog } from './transactionBacklog.selectors';
import { TransactionBacklogType } from './transactionBacklog.types';

const BACKLOG_RETRY_DELAY = 30 * 1000;

export function* emptyPaymentBacklog(): Saga {
  while (true) {
    const authToken: ReturnType<typeof getToken> = yield select(getToken);
    try {
      if (authToken === undefined) {
        throw new Error('This saga should not be called without a token');
      }
      const backlog: ReturnType<typeof getTransactionBacklog> = yield select(
        getTransactionBacklog,
      );
      // eslint-disable-next-line no-restricted-syntax
      for (const backlogItem of backlog) {
        if (backlogItem.type === TransactionBacklogType.VALIDATION) {
          yield call(
            validateTransactionDraft,
            backlogItem.transactionDraftId,
            backlogItem.ticket,
            authToken,
          );
        } else if (backlogItem.type === TransactionBacklogType.CANCELLATION) {
          yield call(
            cancelTransactionDraft,
            backlogItem.transactionDraftId,
            authToken,
          );
        }
        yield put(markTransactionAsProcessed(backlogItem.transactionDraftId));
      }
    } catch (e) {
      // Nothing to do, if the call failed, the payment will not be marked as processed
    }
    yield delay(BACKLOG_RETRY_DELAY);
  }
}

export function* watchtransactionBacklogActions(): Saga {
  yield fork(emptyPaymentBacklog);
}
