import { createReducer, ActionType } from 'typesafe-actions';
import { CustomerActions } from 'redux/customer/customer.reducer';
import * as reloadActions from './reload.actions';
import * as customerActions from '../customer/customer.actions';

export type ReloadActions = ActionType<typeof reloadActions>;

export enum ReloadStatus {
  PENDING,
  DEFERRED,
  ABORTED,
}

const defaultState: ReloadState = {
  reloadAmount: undefined,
  status: ReloadStatus.PENDING,
};

export type ReloadState = {
  reloadAmount?: number;
  transactionDate?: string;
  paymentMean?: string;
  transactionId?: string;
  status: ReloadStatus;
};

const reloadReducer = createReducer<
  ReloadState,
  ReloadActions | CustomerActions
>(defaultState)
  .handleAction(reloadActions.selectAmount, (state, action) => ({
    ...state,
    reloadAmount: action.payload.amount,
    status: ReloadStatus.PENDING,
  }))
  .handleAction(customerActions.disconnectCustomer, () => defaultState);

export default reloadReducer;
