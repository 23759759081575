import { CreateStyled } from '@emotion/styled';
import styled from '@emotion/styled/macro';

export type Theme = {
  color: {
    primary: string;
    title: string;
    textLight: string;
    text: string;
    textOnPrimary: string;
    background: string;
    backgroundhHighlight: string;
    backgroundDisabled: string;
    disabledText: string;
    inputs: string;
  };
  radius: {
    main: string;
  };
  font: {
    family: string;
    size: {
      text: string;
      h2: string;
      title: string;
      big: string;
      inputs: string;
      small: string;
    };
  };
};

export const theme: Theme = {
  color: {
    primary: '#F96D6D',
    title: '#000000',
    textLight: '#605A5A',
    text: '#363636',
    textOnPrimary: '#FFFFFF',
    background: '#F7F7F7',
    inputs: '#DADADA',
    backgroundhHighlight: '#FFFFFF',
    backgroundDisabled: '#cccccc',
    disabledText: '#666666',
  },
  radius: {
    main: '5px',
  },
  font: {
    family: 'avenirHeavy',
    size: {
      text: '21px',
      h2: '26px',
      title: '34px',
      big: '76px',
      inputs: '16px',
      small: '14px',
    },
  },
};

export default styled as CreateStyled<Theme>;
