import { generateEmptyTicketMessage } from 'services/Printer/utils';
import {
  OrderDataForTemplatedTicket,
  TicketMessage,
} from 'services/Printer/printer.types';
import {
  orderDataFake,
  getXmlTicketNameWithSolde,
} from './ticketBuilder.templates';

const generateOrderDataForTemplate = ({
  customerFirstName,
  customerLastName,
  ewalletBalanceAfter,
  ewalletBalanceBefore,
  tpeTicket,
}: PayloadForReloadTicket): OrderDataForTemplatedTicket => {
  return {
    ...orderDataFake,
    user: {
      customerId: 1,
      firstName: customerFirstName,
      lastName: customerLastName,
      badgeNumber: '',
    },
    tpeTicket,
    ewalletBalanceAfterOrder: `${ewalletBalanceAfter / 100} €`,
    ewalletBalanceBeforeOrder: `${ewalletBalanceBefore / 100} €`,
  };
};

export const generateTicketWithTemplate = async (
  orderData: PayloadForReloadTicket,
): Promise<Omit<TicketMessage, 'printerData'>> => {
  const xmlTicketNameWithSolde = await getXmlTicketNameWithSolde();
  const ticket = {
    ...generateEmptyTicketMessage({
      xmlTicket: xmlTicketNameWithSolde, // this is xml template ticket available in the BO
      orderData: generateOrderDataForTemplate(orderData),
    }),
  };
  return ticket;
};

export const buildTicket = (
  orderData: PayloadForReloadTicket,
): Promise<Omit<TicketMessage, 'printerData'>> => {
  return generateTicketWithTemplate(orderData);
};

type PayloadForReloadTicket = {
  customerFirstName: string;
  customerLastName: string;
  ewalletBalanceBefore: number;
  ewalletBalanceAfter: number;
  tpeTicket: string;
};
