import { connect, MapDispatchToPropsFunction } from 'react-redux';
import { triggerModalWithComponent } from 'redux/modal/modal.actions';
import AdminCodeInput from 'components/AdminCodeInput';
import PopupAdminTrigger from './PopupAdminTrigger.component';

type ItemDispatchToProps = MapDispatchToPropsFunction<
  {
    triggerModal: () => unknown;
  },
  {}
>;

const mapDispatchToProps: ItemDispatchToProps = dispatch => ({
  triggerModal: (): unknown =>
    dispatch(triggerModalWithComponent(AdminCodeInput)),
});

export default connect(undefined, mapDispatchToProps)(PopupAdminTrigger);
