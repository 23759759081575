import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import MainPage from 'components/MainPage';
import { push } from 'connected-react-router';
import LangagePicker from 'components/LangagePicker';

const HomePage: React.FunctionComponent<{
  isReady: boolean;
  push: typeof push;
}> = ({ isReady, push: navigate }) => {
  const navigateToNext = useCallback(() => {
    navigate(isReady ? '/badge' : '');
  }, [isReady, navigate]);

  return (
    <MainPage canBadge={isReady} onPress={navigateToNext}>
      <LangagePicker />
      {isReady && <FormattedMessage id="home.welcome" />}
      {!isReady && <FormattedMessage id="home.unavailable" />}
    </MainPage>
  );
};

export default HomePage;
