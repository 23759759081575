import { fork, delay, call, put, takeLatest, select } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { WebBridgeServiceFactory } from 'services/webBridge/index';
import neptingService from 'services/Nepting/nepting.service';
import { getNeptingMerchantId } from 'redux/configuration/configuration.selectors';
import printerService from 'services/Printer/printer.service';
import * as Sentry from '@sentry/react';
import simpleLogService from 'services/SimpleLog';
import {
  addHardwareUpdateFailCount,
  hardwareUpdate,
  hardwareUpdateFailed,
  requestHardwareUpdate,
} from './hardware.actions';
import { getFailedTry } from './hardware.selectors';

const HARDWARE_UPDATE_POLLING_INTERVAL = 1000 * 60 * 5;
const HARDWARE_UPDATE_ERROR_POLLING_INTERVAL = 1000 * 15;

function* updateHarwarehandler(): Saga {
  while (true) {
    try {
      const bridge = WebBridgeServiceFactory.getInstance();

      // eslint-disable-next-line no-unused-vars
      yield call(bridge.searchConnection);

      const neptingMerchantId: ReturnType<typeof getNeptingMerchantId> = yield select(
        getNeptingMerchantId,
      );

      const failedTry: ReturnType<typeof getFailedTry> = yield select(
        getFailedTry,
      );

      const isWebBridgeConnected = !!bridge.ready;
      let success = false;

      if (!isWebBridgeConnected) {
        throw new Error('Not connected');
      }

      if (!neptingMerchantId) {
        yield put(hardwareUpdate(isWebBridgeConnected, null));
      }

      if (neptingMerchantId && !neptingService.initialized) {
        try {
          const shouldForceInitialize = failedTry > 3;

          yield call(
            neptingService.init,
            neptingMerchantId,
            shouldForceInitialize,
          );

          const neptingTerminalInfo = yield call(
            neptingService.getTerminalInfo,
          );

          const printer = yield call(printerService.searchUSBPrinter);
          success = true;

          yield put(
            hardwareUpdate(isWebBridgeConnected, printer, neptingTerminalInfo),
          );
        } catch (error) {
          if (failedTry === 10) {
            simpleLogService.info(
              `[hardware.sagas][updateHarwarehandler] TPE is not connected after 10 retries`,
            );
            Sentry.captureException('TPE is not connected');
          }

          yield put(addHardwareUpdateFailCount());
          yield put(hardwareUpdate(isWebBridgeConnected, null));
        }
      } else if (neptingMerchantId) {
        try {
          const neptingTerminalInfo = yield call(
            neptingService.getTerminalInfo,
          );

          simpleLogService.info(
            `[hardware.sagas][neptingTerminalInfo] TPE is connected`,
          );

          const printer = yield call(printerService.searchUSBPrinter);
          success = true;

          yield put(
            hardwareUpdate(isWebBridgeConnected, printer, neptingTerminalInfo),
          );
        } catch (error) {
          neptingService.disconnect();
          throw new Error('Not connected');
        }
      }

      yield delay(
        success
          ? HARDWARE_UPDATE_POLLING_INTERVAL
          : HARDWARE_UPDATE_ERROR_POLLING_INTERVAL,
      );
    } catch (e) {
      yield put(hardwareUpdateFailed());
      yield delay(HARDWARE_UPDATE_ERROR_POLLING_INTERVAL);
    }
  }
}

export function* watchHardwareActions(): Saga {
  yield fork(updateHarwarehandler);
  yield takeLatest(getType(requestHardwareUpdate), updateHarwarehandler);
}
