import React from 'react';
import styled from 'styled';
import { FormattedMessage } from 'react-intl';
import logout from './logout.svg';
import chevron from './chevron.svg';

interface Props {
  bordered?: boolean;
  isLoggedIn: boolean;
  disconnect: () => {};
  goBack: () => unknown;
  canGoBack?: boolean;
  canDisconnect?: boolean;
}

const FONT_SIZE = '17px';

const Container = styled.div<{ bordered: boolean }>`
  height: 85px;
  background-color: ${({ theme }): string => theme.color.backgroundhHighlight};
  border-bottom-style: solid;
  border-bottom-width: ${({ bordered }): string => (bordered ? '2px' : '0px')};
  border-bottom-color: #f2f2f2;
  display: grid;
  grid-template-columns: 20% 1fr 20%;
  cursor: pointer;
`;

const CenterElement = styled.div`
  grid-column-start: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;
const Logo = styled.img`
  width: 150px;
`;
const Logout = styled.img`
  margin-left: 15px;
`;

const Arrow = styled.img`
  margin-left: 30px;
  margin-right: 20px;
  height: 20px;
`;

const Back = styled.div`
  margin-left: 15px;
  color: ${({ theme }): string => theme.color.primary};
  text-transform: uppercase;
  display: flex;
  align-items: center;
`;

const LogoutContainer = styled.div`
  grid-column-start: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${FONT_SIZE};
  text-transform: uppercase;
`;

const Header: React.FunctionComponent<Props> = ({
  bordered = true,
  goBack,
  isLoggedIn,
  disconnect,
  canGoBack = false,
  canDisconnect = false,
}) => {
  return (
    <Container bordered={bordered} data-testid="container">
      {canGoBack && (
        <Back onClick={goBack}>
          <Arrow src={chevron} />
          <FormattedMessage id="header.back" />
        </Back>
      )}
      <CenterElement>
        <Logo src="https://static-dev.innovorder.fr/io/images/logo4.png" />
      </CenterElement>
      {isLoggedIn && canDisconnect && (
        <LogoutContainer onClick={disconnect}>
          <a>
            <FormattedMessage id="header.logout" />
          </a>
          <Logout src={logout} />
        </LogoutContainer>
      )}
    </Container>
  );
};

export default Header;
