import {
  connect,
  MapDispatchToPropsFunction,
  MapStateToPropsParam,
} from 'react-redux';
import { RootState } from 'redux/store';
import { isAmountSelected } from 'redux/reload/reload.selectors';
import { selectAmount } from 'redux/reload/reload.actions';
import ItemComponent from './Item.component';

type ItemDispatchToProps = MapDispatchToPropsFunction<
  {
    selectAmount: () => unknown;
  },
  { amount: number }
>;

type ItemStateToProps = MapStateToPropsParam<
  { isSelected: boolean },
  { amount: number },
  RootState
>;

const mapStateToProps: ItemStateToProps = (state, { amount }) => ({
  isSelected: isAmountSelected(state, amount),
});

const mapDispatchToProps: ItemDispatchToProps = (dispatch, { amount }) => ({
  selectAmount: (): unknown => dispatch(selectAmount(amount)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ItemComponent);
