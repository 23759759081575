import { SupportedLanguage } from 'translation';
import { RootState } from '../store';
import { AppState } from './app.reducer';

export const getApp = (state: RootState): AppState => state.app;

export const getAppLocale = (state: RootState): SupportedLanguage =>
  getApp(state).locale;

export const getAuthError = (state: RootState): string | undefined =>
  getApp(state).authError;

export const getAdminPasswordError = (state: RootState): string | undefined =>
  getApp(state).adminPasswordError;

export const getToken = (state: RootState): string | undefined =>
  getApp(state).token;

export const getAdminPassword = (state: RootState): string =>
  getApp(state).adminPassword;

export const getTypedAdminPassword = (state: RootState): string =>
  getApp(state).typedAdminPassword;

export const getTypedAdminPasswordLength = (state: RootState): number =>
  getApp(state).typedAdminPassword.length;
