import { injectIntl } from 'react-intl';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { RootState } from 'redux/store';
import { isReady } from 'redux/hardware/hardware.selectors';
import Home from './Home.page';

const mapStateToProps = (state: RootState): { isReady: boolean } => ({
  isReady: isReady(state),
});

const mapDispatchToProps = {
  push,
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Home));
