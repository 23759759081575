import React from 'react';
import { FormattedMessage } from 'react-intl';
import IconPage from 'components/IconPage';
import cb from './cb.svg';

interface Props {
  errorMessage: string | null;
}

const PaymentInProcess: React.FunctionComponent<Props> = () => (
  <IconPage icon={cb} iconAlt="Insert your CB">
    <FormattedMessage id="PaymentInProcess.title" />
  </IconPage>
);

export default PaymentInProcess;
