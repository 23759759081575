import { connect } from 'react-redux';
import { RootState } from 'redux/store';
import { getTypedAdminPasswordLength } from 'redux/app/app.selectors';
import AdminPopupDots from './AdminPopupDots.component';

const mapStateToProps = (
  state: RootState,
): {
  filledDots: number;
} => ({
  filledDots: getTypedAdminPasswordLength(state),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AdminPopupDots);
