import { connect } from 'react-redux';
import { RootState } from 'redux/store';
import { isCustomerLoggedIn } from 'redux/customer/customer.selectors';
import { disconnectCustomer } from 'redux/customer/customer.actions';
import { goBack } from 'connected-react-router';
import HeaderComponent from './Header.component';

const mapStateToProps = (
  state: RootState,
): {
  isLoggedIn: boolean;
} => ({
  isLoggedIn: isCustomerLoggedIn(state),
});

const mapDispatchToProps = {
  disconnect: disconnectCustomer,
  goBack,
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderComponent);
