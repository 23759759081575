import { createAction } from 'typesafe-actions';
import { ContactlessReaderOutputFormat } from './configuration.types';

export const setContactlessReaderOutputFormat = createAction(
  'SET_CONTACTLESS_READER_OUTPUT_FORMAT',
  (value: ContactlessReaderOutputFormat) => ({ value }),
)();

export const setNeptingMerchantId = createAction(
  'SET_NEPTING_MERCHANT_ID',
  (value: string) => ({ value }),
)();

export const printTestTicket = createAction('PRINT_TEST_TICKET')();
