import React, { useEffect, useState } from 'react';
import styled from 'styled';
import { BridgeChatMessage, FakeWebBridge } from '.';
import { humanizeBridgeMessage } from './utils';

const Container = styled.div`
  position: fixed;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  bottom: 20px;
  left: 20px;
  min-width: 200px;
  min-height: 100px;
  display: flex;
  border-radius: 15px;
  background: #222831;
  color: ${({ theme }): string => theme.color.inputs};
  box-shadow: 0 14px 28px rgba(5, 3, 3, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  z-index: 9999;
  padding: 15px;
  max-height: 80vh;
  overflow: scroll;
`;

const ButtonContainer = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
`;

const SButton = styled.button`
  font-size: ${({ theme }): string => theme.font.size.inputs};
  margin: 10px;
  padding: 15px 25px;
  border-radius: 20px;
`;

const Bubble = styled.p<{ isWeb: boolean }>`
  background: ${({ isWeb }): string => (isWeb ? '#ffffff20' : '#FFF')};
  border-radius: 40px;
  padding: 5px 10px;
  margin-bottom: 20px;
  color: ${({ isWeb }): string => (isWeb ? '#FFF' : '#393E46')};
  align-self: ${({ isWeb }): string => (isWeb ? 'flex-start' : 'flex-end')};
`;

const FakeBridge: React.FunctionComponent<{ isAuto: boolean }> = ({
  isAuto = true,
}) => {
  const [service, setService] = useState<FakeWebBridge | undefined>();
  const [messages, setMessages] = useState<BridgeChatMessage[]>([]);
  const [lastMessageId, setMessageId] = useState<string | undefined>();
  useEffect(() => {
    const a = new FakeWebBridge((message, id) => {
      setMessages(message);
      setMessageId(id);
    }, isAuto);
    setService(a);
  }, [FakeWebBridge]);

  return (
    <Container>
      {messages.slice(-20).map(({ value, sender }, index) => (
        <Bubble key={index} isWeb={sender === 'WEB'} title={value}>
          {humanizeBridgeMessage(value)}
        </Bubble>
      ))}
      <ButtonContainer>
        {!isAuto && (
          <>
            <SButton onClick={(): void => service?.pong()}> pong </SButton>
            {lastMessageId && (
              <SButton
                onClick={(): void => service?.pinpadInitialized(lastMessageId)}
              >
                Init TPE
              </SButton>
            )}

            {lastMessageId && (
              <SButton
                onClick={(): void =>
                  service?.pinpadGetTerminalInfo(lastMessageId)
                }
              >
                Info TPE
              </SButton>
            )}
          </>
        )}

        {lastMessageId && (
          <SButton
            onClick={(): void =>
              service?.pinpadRespondValidTransaction(lastMessageId)
            }
            data-testid="validate-cb"
          >
            Validate transaction ✅
          </SButton>
        )}
      </ButtonContainer>
      <SButton
        data-testid="badge"
        onClick={(): void => {
          if (service) service.emulateBadgeScan(1176640541);
        }}
      >
        badge 1176640541
      </SButton>
    </Container>
  );
};

export default FakeBridge;
