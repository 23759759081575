import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import IconPage from 'components/IconPage';
import styled from 'styled';
import nfc from './nfc.svg';

interface Props {
  waitingForBadge: () => unknown;
  error: string | null;
}
const ErrorMessage = styled.p`
  color: ${({ theme }): string => theme.color.primary};
  font-size: ${({ theme }): string => theme.font.size.text};
  margin-top: 40px;
`;

const Badge: React.FunctionComponent<Props> = ({ waitingForBadge, error }) => {
  useEffect(() => {
    waitingForBadge();
  }, [waitingForBadge]);

  return (
    <IconPage icon={nfc} iconAlt="NFC">
      <FormattedMessage id="badge.mainCTA" />
      {error && (
        <ErrorMessage>
          <FormattedMessage id={error} />
        </ErrorMessage>
      )}
    </IconPage>
  );
};

export default Badge;
