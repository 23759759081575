import styled from 'styled';

export default styled.button`
  margin-top: 40px;
  cursor: pointer;
  padding: 20px;
  height: 12%;
  width: 100%;
  font-weight: 900;
  font-family: ${({ theme }): string => theme.font.family}, sans-serif;
  background-color: ${({ theme }): string => theme.color.primary};
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: ${({ theme }): string => theme.color.textOnPrimary};
  font-size: ${({ theme }): string => theme.font.size.text};
  border-radius: ${({ theme }): string => theme.radius.main};
  text-transform: uppercase;
  outline: none;
  border: none;
`;
