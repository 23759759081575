import React from 'react';
import styled from 'styled';
import CenteredIcon from 'components/CenteredIcon';
import Header from 'components/Header';

interface Props {
  icon?: string;
  iconAlt?: string;
  canDisconnect?: boolean;
  animation?: object;
}

const Background = styled.div`
  text-align: center;
  background-color: ${({ theme }): string => theme.color.background};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  color: ${({ theme }): string => theme.color.text};
`;

const Container = styled.div`
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${({ theme }): string => theme.color.text};
`;

const TransparentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 600px 0 0;
  max-width: 700px;
`;

const PageTitle = styled.h1`
  font-weight: 900;
  font-size: ${({ theme }): string => theme.font.size.title};
  margin: 0;
`;

const IconPage: React.FunctionComponent<Props> = ({
  children,
  icon,
  iconAlt,
  canDisconnect,
  animation,
}) => (
  <Background>
    <Header canDisconnect={canDisconnect} />
    <Container>
      <TransparentContainer>
        <CenteredIcon src={icon} alt={iconAlt} animation={animation} />
        <PageTitle>{children}</PageTitle>
      </TransparentContainer>
    </Container>
  </Background>
);

export default IconPage;
