import { connect } from 'react-redux';
import { waitingForBadge } from 'redux/customer/customer.actions';
import { RootState } from 'redux/store';
import { getCustomerAuthError } from 'redux/customer/customer.selectors';
import Badge from './Badge.page';

const mapStateToProps = (
  state: RootState,
): {
  error: string | null;
} => ({
  error: getCustomerAuthError(state),
});

const mapDispatchToProps = {
  waitingForBadge,
};

export default connect(mapStateToProps, mapDispatchToProps)(Badge);
