import { WebBridge, WebBridgeServiceFactory } from 'services/webBridge';
import { BRIDGE_MESSAGE } from 'services/webBridge/webBridge.types';
import { PrinterInfos, PrinterMap, TicketMessage } from './printer.types';
import {
  extractLastUsbPrinterFromMap,
  generateEmptyTicketMessage,
} from './utils';

let instance: PrinterService;

export class PrinterService {
  private webBridge: WebBridge;

  public enabled = false;

  private printer: PrinterInfos | null = null;

  private searchPromiseResolver: null | ((value: PrinterMap) => void) = null;

  private searchUSBPromiseResolver:
    | null
    | ((value: PrinterInfos | null) => void) = null;

  constructor(webBridge: WebBridge, testMode = false) {
    this.webBridge = webBridge;

    if (instance && !testMode) {
      return;
    }

    this.init();
    instance = this;
  }

  init = (): void => {
    this.startPrintersEventListener();
  };

  startPrintersEventListener = (): void => {
    this.webBridge.on(
      BRIDGE_MESSAGE.PRINTER_LIST_UPDATED,
      'PRINT_SERVICE',
      printers => {
        const printer = extractLastUsbPrinterFromMap(
          (printers as unknown) as PrinterMap,
        );

        if (printer) {
          this.printer = printer;
          this.resolveUSBSearch(printer);
        }

        this.resolveGlobalSearch((printers as unknown) as PrinterMap);
      },
    );
  };

  resolveUSBSearch = (printer: PrinterInfos): void => {
    if (this.searchUSBPromiseResolver) {
      this.searchUSBPromiseResolver(printer);
      this.searchUSBPromiseResolver = null;
    }
  };

  resolveGlobalSearch = (printers: PrinterMap): void => {
    if (this.searchPromiseResolver) {
      this.searchPromiseResolver(printers);
      this.searchPromiseResolver = null;
    }
  };

  search = (): Promise<PrinterMap | null> => {
    if (this.webBridge.ready) {
      this.webBridge.emitMessage(BRIDGE_MESSAGE.PRINTER_START_DISCOVER, null);

      return new Promise(resolve => {
        this.searchPromiseResolver = resolve;

        // resolving null if no printers found
        setTimeout(() => {
          resolve(null);
        }, 5000);
      });
    }

    return Promise.resolve(null);
  };

  searchUSBPrinter = (): Promise<PrinterInfos | null> => {
    if (this.webBridge.ready) {
      this.webBridge.emitMessage(BRIDGE_MESSAGE.PRINTER_START_DISCOVER, null);

      return new Promise(resolve => {
        this.searchUSBPromiseResolver = resolve;

        // resolving null if no printers found
        setTimeout(() => {
          resolve(null);
        }, 5000);
      });
    }

    return Promise.resolve(null);
  };

  printTestTicket = (): void => {
    if (this.printer !== null) {
      const ticket = {
        ...generateEmptyTicketMessage({ isTest: true }),
        printerData: this.printer,
      };

      this.webBridge.emitMessage(BRIDGE_MESSAGE.PRINT_TICKET, ticket);
    }
  };

  print = (ticketData: Omit<TicketMessage, 'printerData'>): void => {
    if (this.printer !== null) {
      const ticket = {
        ...ticketData,
        printerData: this.printer,
      };

      this.webBridge.emitMessage(BRIDGE_MESSAGE.PRINT_TICKET, ticket);
    }
  };

  printTpeTicket = (tpeTicket: string): void => {
    this.print(generateEmptyTicketMessage({ orderData: { tpeTicket } }));
  };
}

const singleton = new PrinterService(WebBridgeServiceFactory.getInstance());

export default singleton;
