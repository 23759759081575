import { connect } from 'react-redux';
import { RootState } from 'redux/store';
import {
  isModalOpen,
  getModalContentId,
  getModalCtaId,
  getComponent,
} from 'redux/modal/modal.selectors';
import { closeModal } from 'redux/modal/modal.actions';
import Modal from './Modal.component';

const mapStateToProps = (
  state: RootState,
): {
  isOpen: boolean;
  contentId: string;
  ctaId: string;
  component?: React.FunctionComponent<{ onClose: () => unknown }>;
} => ({
  isOpen: isModalOpen(state),
  contentId: getModalContentId(state),
  ctaId: getModalCtaId(state),
  component: getComponent(state),
});

const mapDispatchToProps = {
  closeModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
