import { connect } from 'react-redux';
import { RootState } from 'redux/store';
import {
  getCustomerName,
  getCustomerBalance,
  getCustomerAccountPaymentType,
} from 'redux/customer/customer.selectors';
import { hasAmount } from 'redux/reload/reload.selectors';
import { startPayment } from 'redux/reload/reload.actions';
import ReloadPage from './Reload.page';
import { AccountPaymentType } from '../../services/innovorder';

const mapStateToProps = (
  state: RootState,
): {
  balance: number | undefined;
  name: string | undefined;
  hasAmount: boolean;
  accountPaymentType: AccountPaymentType | undefined;
} => ({
  balance: getCustomerBalance(state),
  name: getCustomerName(state),
  hasAmount: hasAmount(state),
  accountPaymentType: getCustomerAccountPaymentType(state),
});

const mapDispatchToProps = {
  startPayment,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReloadPage);
