import { connect } from 'react-redux';
import { RootState } from 'redux/store';
import { changeLanguage } from 'redux/app/app.actions';
import { getAppLocale } from 'redux/app/app.selectors';
import { SupportedLanguage } from 'translation';
import Modal from './LangagePicker.component';

const mapStateToProps = (
  state: RootState,
): {
  appLocale: SupportedLanguage;
} => ({
  appLocale: getAppLocale(state),
});

const mapDispatchToProps = {
  changeLanguage,
};

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
