import React from 'react';
import { FormattedMessage } from 'react-intl';
import MainPage from 'components/MainPage';

const MaintenancePage: React.FunctionComponent<{}> = () => (
  <MainPage>
    <FormattedMessage id="maintenance.message" />
  </MainPage>
);

export default MaintenancePage;
