/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/ban-ts-ignore */

import {
  BRIDGE_MESSAGE,
  EventDataToSend,
  WebBridgeMessage,
} from 'services/webBridge/webBridge.types';

export type BridgeChatMessage = {
  sender: 'WEB' | 'NATIVE';
  value: BRIDGE_MESSAGE;
};
export class FakeWebBridge {
  callback: (event: BridgeChatMessage[], messageId?: string) => void;

  private auto: boolean;

  private messageHistory: BridgeChatMessage[];

  constructor(
    callback: (event: BridgeChatMessage[], messageId?: string) => void,
    automatique = true,
  ) {
    this.callback = callback;
    this.auto = automatique;
    this.messageHistory = [];

    // @ts-ignore WebViewBridge exist
    if (window.WebViewBridge) {
      // @ts-ignore WebViewBridge exist
      window.WebViewBridge.send = this.onMessage;
    } else {
      // @ts-ignore WebViewBridge exist
      window.WebViewBridge = {
        send: this.onMessage,
      };
    }
  }

  unserializeMessage = (
    serializedMessage: string,
  ): WebBridgeMessage & {
    eventData: EventDataToSend;
  } => {
    const messageObject = JSON.parse(serializedMessage) as WebBridgeMessage;

    return {
      eventName: messageObject.eventName,
      eventData: messageObject.eventData as EventDataToSend,
      eventTimestamp: messageObject.eventTimestamp,
    };
  };

  onMessage = (e: string): void => {
    const messageObject = this.unserializeMessage(e);
    console.log('🚀 ~ FakeWebBridge ~ e:', messageObject);
    this.messageHistory.push({
      sender: 'WEB',
      value: messageObject.eventName,
    });

    this.callback(this.messageHistory, messageObject.eventData?.messageId);

    if (!this.auto) {
      return;
    }

    switch (messageObject.eventName) {
      case BRIDGE_MESSAGE.PING:
        this.pong();
        break;
      case BRIDGE_MESSAGE.PONG:
        this.ping();
        break;
      case BRIDGE_MESSAGE.NEPTING_PINPAD_INITIALIZE:
        this.pinpadInitialized(messageObject.eventData?.messageId);
        break;
      case BRIDGE_MESSAGE.NEPTING_PINPAD_GET_TERMINAL_INFORMATION:
        this.pinpadGetTerminalInfo(messageObject.eventData.messageId);
        break;

      default:
        break;
    }
  };

  pong = (): void => {
    this.emitMessage(BRIDGE_MESSAGE.PONG, null);
  };

  ping = (): void => {
    this.emitMessage(BRIDGE_MESSAGE.BRIDGE_READY, null);
  };

  pinpadInitialized = (messageId?: string): void => {
    if (!messageId) {
      console.warn('no message id');
    }

    this.emitMessage(BRIDGE_MESSAGE.NEPTING_PINPAD_RESPONSE, {
      messageId,
      payload: {},
    });
  };

  pinpadGetTerminalInfo = (messageId?: string): void => {
    if (!messageId) {
      console.warn('no message id');
    }

    this.emitMessage(BRIDGE_MESSAGE.NEPTING_PINPAD_RESPONSE, {
      messageId,
      payload: {
        model: 'Q25 fake',
        firmwareVersion: 'io-faking-it',
        serialNumber: 'lfqezhur-zarejrgehg-fake',
        clientVersion: 'none',
      },
    });
  };

  pinpadRespondValidTransaction = (messageId: string): void => {
    this.emitMessage(BRIDGE_MESSAGE.NEPTING_PINPAD_RESPONSE, {
      messageId,
      payload: {
        merchantTicket: '',
        merchantLabel: '',
        maskedPan: '',
        merchantTransactionId: '12',
        isTestCard: true,
        isSignatureRequired: false,
        handWrittenTicket: '',
        customerTicket: 'ticket test',
        initialDebitTransactionId: null,
        cardEndOfValidity: '12.2300',
        isLocalMode: true,
        cardToken: 'string',
        dateTime: 'string',
        authorizationNumber: 'string',
        authorizationCode: 'string',
        localTransactionCount: 1,
        amount: 500,
      },
    });
  };

  emitMessage = (name: BRIDGE_MESSAGE, content: unknown): void => {
    this.messageHistory.push({
      sender: 'NATIVE',
      value: name,
    });

    this.callback(this.messageHistory, undefined);

    // @ts-ignore WebViewBridge exist
    window.WebViewBridge.onMessage(
      JSON.stringify({
        eventName: name,
        eventData: content,
        eventTimestamp: new Date().toDateString(),
      }),
    );
  };

  emulateBadgeScan = (badgeNumber: number): void => {
    const badgeNumberString = badgeNumber.toString();
    const badgeNumberAsStringArray = badgeNumberString.match(/.{1,1}/g);
    const badgeNumberAsKeyEvents =
      badgeNumberAsStringArray?.map(char => {
        return new KeyboardEvent('keypress', {
          bubbles: true,
          key: char,
        });
      }) ?? [];

    const enterEvent = new KeyboardEvent('keypress', {
      bubbles: true,
      key: 'enter',
    });

    const events = [...badgeNumberAsKeyEvents, enterEvent];

    events.forEach(e => {
      // @ts-ignore WebViewBridge exist
      window.dispatchEvent(e);
    });
  };
}
