import React from 'react';
import { FormattedMessage } from 'react-intl';
import IconPage from 'components/IconPage';
import cross from './cross.svg';

const MESSAGES = {
  'validation-failed': (
    <FormattedMessage id="error.transaction-validation-failed.title" />
  ),
  'transaction-creation-failed': (
    <FormattedMessage id="error.transaction-creation-failed.title" />
  ),
  payment: <FormattedMessage id="GenericError.title" />,
};

interface Props {
  match: {
    params: {
      error: keyof typeof MESSAGES;
    };
  };
}

const GenericError: React.FunctionComponent<Props> = ({
  match: {
    params: { error },
  },
}) => (
  <IconPage icon={cross} iconAlt="Error">
    {MESSAGES[error]}
  </IconPage>
);

export default GenericError;
