import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchToken } from 'redux/app/app.actions';
import { RootState } from 'redux/store';
import { getAuthError } from 'redux/app/app.selectors';
import LoginPage from './Login.page';

const mapStateToProps = (
  state: RootState,
): { errorMessage: string | undefined } => ({
  errorMessage: getAuthError(state),
});

const mapDispatchToProps = {
  fetchToken,
};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(LoginPage),
);
