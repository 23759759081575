import { put, takeLatest, select, fork } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { Action } from 'redux';
import { requestHardwareUpdate } from 'redux/hardware/hardware.actions';
import printerService from 'services/Printer/printer.service';
import { setNeptingMerchantId, printTestTicket } from './configuration.actions';
import { getNeptingMerchantId } from './configuration.selectors';

function* setNeptingMerchantIdHandler(): Saga {
  const neptingMerchantId: ReturnType<typeof getNeptingMerchantId> = yield select(
    getNeptingMerchantId,
  );

  if (neptingMerchantId && neptingMerchantId?.length >= 4) {
    yield put<Action>(requestHardwareUpdate());
  }
}

function* printTestTicketHandler(): Saga {
  printerService.printTestTicket();

  yield true;
}

export function* watchConfigActions(): Saga {
  yield fork(printTestTicketHandler);
  yield takeLatest(getType(setNeptingMerchantId), setNeptingMerchantIdHandler);
  yield takeLatest(getType(printTestTicket), printTestTicketHandler);
}
