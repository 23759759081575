import { connect } from 'react-redux';
import { RootState } from 'redux/store';
import {
  getContactlessReaderOutputFormat,
  getNeptingMerchantId,
} from 'redux/configuration/configuration.selectors';
import {
  setContactlessReaderOutputFormat,
  setNeptingMerchantId,
} from 'redux/configuration/configuration.actions';
import { ContactlessReaderOutputFormat } from 'redux/configuration/configuration.types';
import SettingsForm from './SettingsForm.component';

const mapStateToProps = (
  state: RootState,
): {
  contactlessReaderOuputFormat: ContactlessReaderOutputFormat;
  neptingMerchantId: string | null;
} => ({
  contactlessReaderOuputFormat: getContactlessReaderOutputFormat(state),
  neptingMerchantId: getNeptingMerchantId(state),
});

const mapDispatchToProps = {
  setContactlessReaderOutputFormat,
  setNeptingMerchantId,
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsForm);
