import React from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import styled from 'styled';
import Header from 'components/Header';
import AmountSelector from './AmountSelector';
import { AccountPaymentType } from '../../services/innovorder';

const Background = styled.div`
  position: relative;
  height: 100vh;
  width: 100%;
  background-color: ${({ theme }): string => theme.color.background};
`;

const HeadContainer = styled.div`
  background-color: ${({ theme }): string => theme.color.backgroundhHighlight};
  border-bottom: 2px solid #f2f2f2;
  padding-bottom: 20px;
  margin-bottom: 50px;
`;

const Message = styled.h1`
  text-align: center;
  font-weight: 900;
  margin-top: 20px;
  text-transform: uppercase;
  font-size: ${({ theme }): string => theme.font.size.text};
  color: ${({ theme }): string => theme.color.title};
`;

const Balance = styled.h2`
  margin: 0;
  margin-top: 0;
  text-align: center;
  font-weight: 900;
  font-size: ${({ theme }): string => theme.font.size.big};
  color: ${({ theme }): string => theme.color.primary};
`;

const ReloadButton = styled.button`
  position: absolute;
  bottom: 0;
  height: 12%;
  width: 100%;
  font-weight: 900;
  background-color: ${({ theme }): string => theme.color.primary};
  font-family: ${({ theme }): string => theme.font.family}, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: ${({ theme }): string => theme.color.textOnPrimary};
  font-size: ${({ theme }): string => theme.font.size.title};
  text-transform: uppercase;
  outline: none;
  border: none;
`;

interface Props {
  balance: number | undefined;
  name: string | undefined;
  hasAmount: boolean;
  accountPaymentType: AccountPaymentType | undefined;
  startPayment: () => unknown;
}

const Reload: React.FunctionComponent<Props> = ({
  name,
  balance,
  hasAmount,
  accountPaymentType,
  startPayment,
}) => {
  if (name === undefined || balance === undefined) {
    throw new Error(
      'This page should not be rendered without name and balance',
    );
  }
  return (
    <Background>
      <HeadContainer>
        <Header bordered={false} canDisconnect />
        <Message>
          <FormattedMessage
            id="reload.greetings"
            values={{
              name,
            }}
          />
        </Message>
        <Balance>
          <FormattedNumber
            style="currency"
            currency="EUR"
            value={balance / 100}
          />
        </Balance>
      </HeadContainer>
      <AmountSelector accountPaymentType={accountPaymentType} />
      {hasAmount && (
        <ReloadButton onTouchStart={startPayment} data-testid="reload-btn">
          <FormattedMessage id="reload.next" />
        </ReloadButton>
      )}
    </Background>
  );
};

export default Reload;
