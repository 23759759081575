import React, { useEffect } from 'react';
import styled from 'styled';
import Header from 'components/Header';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import CustomAmountPad from './CustomAmountPad';

const Background = styled.div`
  position: relative;
  height: 100vh;
  width: 100%;
  background-color: ${({ theme }): string => theme.color.background};
`;

const HeadContainer = styled.div`
  background-color: ${({ theme }): string => theme.color.backgroundhHighlight};
  border-bottom: 2px solid #f2f2f2;
  padding-bottom: 20px;
  margin-bottom: 50px;
`;

const Message = styled.h1`
  text-align: center;
  font-weight: 900;
  margin-top: 20px;
  text-transform: uppercase;
  font-size: ${({ theme }): string => theme.font.size.text};
  color: ${({ theme }): string => theme.color.title};
`;

const Balance = styled.h2`
  margin: 0;
  margin-top: 0;
  text-align: center;
  font-weight: 900;
  font-size: ${({ theme }): string => theme.font.size.big};
  color: ${({ theme }): string => theme.color.primary};
`;

const ReloadButton = styled.button`
  position: absolute;
  bottom: 0;
  height: 12%;
  width: 100%;
  font-weight: 900;
  font-family: ${({ theme }): string => theme.font.family}, sans-serif;
  background-color: ${({ theme }): string => theme.color.primary};
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: ${({ theme }): string => theme.color.textOnPrimary};
  font-size: ${({ theme }): string => theme.font.size.title};
  text-transform: uppercase;
  outline: none;
  border: none;
`;

interface Props {
  startPayment: () => {};
  selectAmount: (number: number) => unknown;
  reloadAmount: number | undefined;
  hasAmount: boolean;
}

const CustomAmount: React.FunctionComponent<Props> = ({
  startPayment,
  reloadAmount = 0,
  hasAmount,
  selectAmount,
}) => {
  useEffect(() => {
    selectAmount(0);
  }, [selectAmount]);
  return (
    <Background>
      <HeadContainer>
        <Header bordered={false} canGoBack canDisconnect />
        <Message>
          <FormattedMessage id="customAmount.credit" />
        </Message>
        <Balance>
          <FormattedNumber
            style="currency"
            currency="EUR"
            minimumFractionDigits={0}
            value={reloadAmount / 100}
          />
        </Balance>
      </HeadContainer>
      <CustomAmountPad amount={reloadAmount} />
      {hasAmount && (
        <ReloadButton onTouchStart={startPayment} data-testid="reload-btn">
          <FormattedMessage id="reload.next" />
        </ReloadButton>
      )}
    </Background>
  );
};

export default CustomAmount;
