import { RootState } from '../store';
import { NeptingState } from './nepting.reducer';

export const getNepting = (state: RootState): NeptingState => state.nepting;
export const getNeptingTicket = (state: RootState): string | undefined =>
  getNepting(state).ticket;
export const getNeptingErrorMessage = (state: RootState): string | null =>
  getNepting(state).errorMessage;
export const getNeptingPaymentResult = (state: RootState): boolean | null =>
  getNepting(state).isPayementSuccessful;
