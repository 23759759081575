import React from 'react';
import styled from 'styled';
import CenteredIcon from 'components/CenteredIcon';
import PopupAdminTrigger from 'components/PopupAdminTrigger';
import touch from './touch.svg';
import backgroundImage from './backgroundImage.jpg';

const Background = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  background: url(${backgroundImage}) rgba(0, 0, 0, 0.3);
  background-position: center;
  background-size: cover;
  background-blend-mode: multiply;
  min-height: 100vh;
  color: ${({ theme }): string => theme.color.textOnPrimary};
  text-align: center;
  text-decoration: none;
`;

const MainPageTitle = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  align-items: center;
  font-weight: 900;
  font-size: ${({ theme }): string => theme.font.size.title};
  margin: 0;
  text-transform: uppercase;
`;

interface Props {
  canBadge?: boolean;
  onPress?: () => void;
}

const MainPage: React.FunctionComponent<Props> = ({
  children,
  canBadge,
  onPress,
}) => {
  return (
    <>
      <PopupAdminTrigger />
      <Background onTouchStart={onPress} data-testid="splashscreen">
        {canBadge && <CenteredIcon src={touch} alt="Touch Icon" />}
        <MainPageTitle>{children}</MainPageTitle>
      </Background>
    </>
  );
};

export default MainPage;
