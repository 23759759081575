import { RootState } from '../store';
import { CustomerState } from './customer.reducer';
import { AccountPaymentType, Customer } from '../../services/innovorder';

export const getCustomerState = (state: RootState): CustomerState =>
  state.customer;

export const getCustomer = (state: RootState): Customer | null =>
  getCustomerState(state).customer;

export const isCustomerLoggedIn = (state: RootState): boolean =>
  getCustomer(state) !== null;

export const getCustomerName = (state: RootState): string | undefined => {
  const customer = getCustomer(state);

  if (customer === null) {
    return undefined;
  }

  return `${customer.firstName} ${customer.lastName}`;
};

export const getCustomerAccountPaymentType = (
  state: RootState,
): AccountPaymentType | undefined => {
  const accountPaymentType = getCustomer(state)?.accountPaymentType;

  if (accountPaymentType === null) {
    return undefined;
  }
  return accountPaymentType;
};

export const getCustomerBalance = (state: RootState): number | undefined =>
  getCustomer(state)?.customerBalance;

export const getCustomerAuthError = (state: RootState): string | null =>
  getCustomerState(state).error;
