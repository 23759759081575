import { createReducer, ActionType } from 'typesafe-actions';
import { SupportedLanguage, getLocale } from 'translation';
import * as appActions from './app.actions';

export type AppActions = ActionType<typeof appActions>;

const defaultState = {
  locale: getLocale(),
  authError: undefined,
  token: undefined,
  adminPasswordError: undefined,
  adminPassword: process.env.REACT_APP_ADMIN_PASSWORD!,
  typedAdminPassword: '',
};

export interface AppState {
  locale: SupportedLanguage;
  authError: string | undefined;
  token: string | undefined;
  adminPassword: string;
  adminPasswordError: string | undefined;
  typedAdminPassword: string;
}

const appReducer = createReducer<AppState, AppActions>(defaultState)
  .handleAction(appActions.changeLanguage, (state, action) => ({
    ...state,
    locale: action.payload.language,
  }))
  .handleAction(appActions.updateToken, (state, action) => ({
    ...state,
    token: action.payload.token,
  }))
  .handleAction(appActions.dispatchAuthError, (state, action) => ({
    ...state,
    authError: action.payload.errorMessage,
  }))
  .handleAction(appActions.dispatchAdminPasswordError, (state, action) => ({
    ...state,
    adminPasswordError: action.payload.errorMessage,
  }))
  .handleAction(appActions.logout, state => ({
    ...state,
    error: undefined,
    token: undefined,
  }))
  .handleAction(appActions.enterCharForAdminPassword, (state, action) => ({
    ...state,
    typedAdminPassword: state.typedAdminPassword.concat(
      action.payload.character,
    ),
  }))
  .handleAction(appActions.resetTypedAdminPassword, state => ({
    ...state,
    typedAdminPassword: defaultState.typedAdminPassword,
  }))
  .handleAction(appActions.flushAdminPopup, state => ({
    ...state,
    adminPasswordError: defaultState.adminPasswordError,
    typedAdminPassword: defaultState.typedAdminPassword,
  }));

export default appReducer;
