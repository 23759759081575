import React from 'react';
import styled from 'styled';
import { motion } from 'framer-motion';
import deleteImg from './delete.svg';

interface Props {
  amount: number;
  onChange: (amount: number) => unknown;
}

const Container = styled.div`
  display: flex;
  justify-content: center;
`;
const Pad = styled.ul`
  display: flex;
  max-width: 600px;
  flex-wrap: wrap;
  justify-content: flex-end;
`;
const Icon = styled.img`
  height: 44px;
`;
const Key = styled(motion.li)`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${({ theme }): string => theme.radius.main};
  height: 100px;
  width: 200px;
  font-size: ${({ theme }): string => theme.font.size.title};
`;

const keys = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0];

const CustomAmountPad: React.FunctionComponent<Props> = ({
  amount,
  onChange,
}) => {
  const addEntry = (key: number): void => {
    onChange(10 * amount + key * 100);
  };

  const removeEntry = (): void => {
    onChange(Math.floor(amount / 1000) * 100);
  };

  return (
    <Container>
      <Pad>
        {keys.map(key => (
          <Key
            onTouchStart={(): void => addEntry(key)}
            key={key}
            whileTap={{ scale: 0.6, backgroundColor: '#ddd' }}
          >
            {key}
          </Key>
        ))}
        <Key onTouchStart={removeEntry} whileTap={{ scale: 0.6 }}>
          <Icon src={deleteImg} alt="remove" />
        </Key>
      </Pad>
    </Container>
  );
};

export default CustomAmountPad;
