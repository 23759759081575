import { createReducer, ActionType } from 'typesafe-actions';
import * as modalActions from './modal.actions';

export type ModalActions = ActionType<typeof modalActions>;

const defaultState = {
  isOpen: false,
  component: undefined,
  content: {
    messageId: '',
    ctaId: '',
  },
};

export type ModalContent = {
  messageId: string;
  ctaId: string;
};
export type ModalState = {
  isOpen: boolean;
  content: ModalContent;
  component: React.FunctionComponent<{ onClose: () => unknown }> | undefined;
};

const modalReducer = createReducer<ModalState, ModalActions>(defaultState)
  .handleAction(modalActions.triggerModal, (state, action) => ({
    ...state,
    isOpen: true,
    content: action.payload.content,
  }))
  .handleAction(modalActions.triggerModalWithComponent, (state, action) => ({
    ...state,
    isOpen: true,
    component: action.payload.component,
  }))
  .handleAction(modalActions.closeModal, () => defaultState);
export default modalReducer;
