import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import './index.css';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'emotion-theming';
import { theme } from 'styled';
import Router from 'Router';
import { PersistGate } from 'redux-persist/integration/react';
import persistedStore, { history } from 'redux/store';
import FakeWebBridge from 'services/FakeWebBridge/ui';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_ENV,
});

const { store, persistor } = persistedStore;
const useFakebridge = process.env.REACT_APP_WITH_FAKE_BRIDGE;

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <PersistGate loading={null} persistor={persistor}>
        {useFakebridge && <FakeWebBridge isAuto />}
        <Router history={history} /> {/* pass history object as props */}
      </PersistGate>
    </ThemeProvider>
  </Provider>,
  // eslint-disable-next-line no-undef
  document.getElementById('root'),
);
