import React, { useEffect } from 'react';
import styled from 'styled';
import { FormattedMessage } from 'react-intl';
import AdminPopupPad from './AdminPopupPad';
import AdminPopupDots from './AdminPopupDots';
import cross from './cross.svg';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Close = styled.img`
  position: absolute;
  top: 20px;
  right: 25px;
  width: 15px;
  height: 15px;
`;
const Message = styled.p`
  font-size: ${({ theme }): string => theme.font.size.inputs};
  color: ${({ theme }): string => theme.color.text};
  margin-bottom: 40px;
  align-items: center;
`;

const ErrorMessage = styled.p`
  color: ${({ theme }): string => theme.color.primary};
  margin-top: -14px;
`;

interface Props {
  onClose: () => unknown;
  flushAdminPopup: () => void;
  errorMessage: string | undefined;
}

const AdminCodeInput: React.FunctionComponent<Props> = ({
  onClose,
  flushAdminPopup,
  errorMessage,
}) => {
  useEffect(() => {
    return (): void => flushAdminPopup();
  }, ['flushAdminPopup']);

  return (
    <Container>
      <Close src={cross} onClick={onClose} />
      <Message>
        <FormattedMessage id="adminPopup.code" />
      </Message>
      <AdminPopupDots />
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      <AdminPopupPad />
    </Container>
  );
};

export default AdminCodeInput;
