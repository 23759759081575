import { connect } from 'react-redux';
import { logout } from 'redux/app/app.actions';
import { RootState } from 'redux/store';
import { TerminalInfo } from 'services/Nepting/nepting.types';
import {
  getPrinterInfo,
  getTerminalInfo,
} from 'redux/hardware/hardware.selectors';
import { printTestTicket } from 'redux/configuration/configuration.actions';
import { PrinterInfos } from 'services/Printer/printer.types';
import ConfigPage from './Config.page';

const mapStateToProps = (
  state: RootState,
): {
  terminalInfo: TerminalInfo | undefined;
  printerInfo: PrinterInfos | null | undefined;
} => ({
  terminalInfo: getTerminalInfo(state),
  printerInfo: getPrinterInfo(state),
});

const mapDispatchToProps = {
  logout,
  printTestTicket,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfigPage);
