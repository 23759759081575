import React, { useState, useEffect } from 'react';
import styled, { Theme } from 'styled';
import { useTheme } from 'emotion-theming';
import { motion, useAnimation } from 'framer-motion';

const Container = styled(motion.ul)`
  display: flex;
  justify-content: space-between;
  list-style: none;
  width: 150px;
  margin-bottom: 30px;
`;

const Dots = styled(motion.li)`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 1px solid;
  border-color: ${({ theme }): string => theme.color.text};
  transition: 0.3s ease;
`;

interface Props {
  filledDots: number;
}

const AdminPopupDots: React.FunctionComponent<Props> = ({ filledDots }) => {
  const theme: Theme = useTheme();
  const [dot, setDots] = useState(filledDots);
  const controls = useAnimation();

  const variants = {
    filled: {
      backgroundColor: theme.color.text,
    },
    empty: {
      backgroundColor: 'transparent',
    },
  };

  useEffect(() => {
    if (dot === 4 && filledDots === 0) {
      controls.start({
        transform: ['translateX(4px)', 'translateX(-4px)', 'translateX(4px)'],
      });
    }
    setDots(filledDots);
  }, [filledDots]);

  const dots: Array<JSX.Element> = new Array(4).fill(null).map((el, index) => {
    return (
      <Dots
        key={index}
        variants={variants}
        animate={index < filledDots ? 'filled' : 'empty'}
        data-testid={index < filledDots ? 'filled' : 'empty'}
      />
    );
  });

  return (
    <Container
      animate={controls}
      transition={{
        duration: 0.2,
        loop: 2,
      }}
    >
      {dots}
    </Container>
  );
};
export default AdminPopupDots;
