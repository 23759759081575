import styled from '@emotion/styled';
import React, { useCallback } from 'react';
import { SupportedLanguage } from 'translation';

const PickerContainer = styled.div`
  position: absolute;
  top: 40px;
  right: 40px;
  display: flex;
  height: 80px;
  width: 80px;
  justify-content: center;
  align-items: center;
`;

interface Props {
  changeLanguage: (langage: SupportedLanguage) => unknown;
  appLocale: SupportedLanguage;
}

const ModalContainer: React.FunctionComponent<Props> = ({
  changeLanguage,
  appLocale,
}) => {
  const handleToggleLangage = useCallback(
    event => {
      event.stopPropagation();
      changeLanguage(
        appLocale === SupportedLanguage.en
          ? SupportedLanguage.fr
          : SupportedLanguage.en,
      );
    },
    [appLocale, changeLanguage],
  );
  return (
    <PickerContainer onTouchStart={handleToggleLangage}>
      <h1>{appLocale === SupportedLanguage.en ? '🇫🇷' : '🇬🇧'}</h1>
    </PickerContainer>
  );
};

export default ModalContainer;
