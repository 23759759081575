import { all } from 'redux-saga/effects';
import { watchCustomerActions } from './customer/customer.sagas';
import { watchHardwareActions } from './hardware/hardware.sagas';
import { watchReloadActions } from './reload/reload.sagas';
import { watchNeptingActions } from './nepting/nepting.sagas';
import { watchtransactionBacklogActions } from './transactionBacklog/transactionBacklog.sagas';
import { watchAppActions } from './app/app.sagas';
import { watchConfigActions } from './configuration/configuration.sagas';

// Notice how we now only export the rootSaga,
// single entry point to start all Sagas at once
export default function* rootSaga(): Saga {
  yield all([
    watchCustomerActions(),
    watchHardwareActions(),
    watchReloadActions(),
    watchNeptingActions(),
    watchtransactionBacklogActions(),
    watchAppActions(),
    watchConfigActions(),
  ]);
}
