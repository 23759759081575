import styled from 'styled';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ContactlessReaderOutputFormat } from 'redux/configuration/configuration.types';

const Input = styled.input`
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme }): string => theme.color.inputs};
  border-radius: ${({ theme }): string => theme.radius.main};
  width: 100%;
  height: 50px;
  padding: 18px 15px;
  font-size: ${({ theme }): string => theme.font.size.inputs};
`;

const Container = styled.div`
  background-color: ${({ theme }): string => theme.color.backgroundhHighlight};
  margin-top: 40px;
  width: 95%;
`;

const Message = styled.p`
  color: ${({ theme }): string => theme.color.textLight};
  font-size: ${({ theme }): string => theme.font.size.small};
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 10px;
`;

interface Props {
  contactlessReaderOuputFormat: ContactlessReaderOutputFormat;
  neptingMerchantId: string | null;
  setContactlessReaderOutputFormat: (
    value: ContactlessReaderOutputFormat,
  ) => unknown;
  setNeptingMerchantId: (value: string) => unknown;
}

const SettingsForm: React.FunctionComponent<Props> = ({
  // setContactlessReaderOutputFormat,
  // contactlessReaderOuputFormat,
  setNeptingMerchantId,
  neptingMerchantId,
}) => (
  <Container>
    <Message>
      <FormattedMessage id="config.neptingMerchantId" />
    </Message>
    <Input
      type="text"
      value={neptingMerchantId ?? ''}
      onChange={(event): void => {
        setNeptingMerchantId(event.target.value);
      }}
      placeholder={'Merchant ID'}
    />
  </Container>
);

export default SettingsForm;
