import { connect } from 'react-redux';
import { startPayment, selectAmount } from 'redux/reload/reload.actions';
import { getReloadAmount, hasAmount } from 'redux/reload/reload.selectors';
import { RootState } from 'redux/store';
import CustomAmountPage from './CustomAmount.page';

const mapStateToProps = (
  state: RootState,
): { reloadAmount: number | undefined; hasAmount: boolean } => ({
  reloadAmount: getReloadAmount(state),
  hasAmount: hasAmount(state),
});

const mapDispatchToProps = {
  startPayment,
  selectAmount,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomAmountPage);
