import { createReducer, ActionType } from 'typesafe-actions';
import * as serviceAccountActions from './serviceAccount.actions';

export type ServiceAccountActions = ActionType<typeof serviceAccountActions>;

const defaultState = null;

export type ServiceAccountState = ServiceAccount | null;

export interface ServiceAccount {
  userId: number;
  email: string;
  brandId: number;
}

const serviceAccountReducer = createReducer<
  ServiceAccountState,
  ServiceAccountActions
>(defaultState).handleAction(
  serviceAccountActions.updateServiceAccount,
  (state, { payload }) => payload.serviceAccount,
);

export default serviceAccountReducer;
