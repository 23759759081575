import { combineReducers, createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createBrowserHistory, History } from 'history';
import { routerMiddleware, connectRouter } from 'connected-react-router';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import rootSaga from './sagas';
import appReducer from './app/app.reducer';
import customerReducer from './customer/customer.reducer';
import hardwareReducer from './hardware/hardware.reducer';
import reloadReducer from './reload/reload.reducer';
import modalReducer from './modal/modal.reducer';
import serviceAccountReducer from './serviceAccount/serviceAccount.reducer';
import transactionBacklogReducer from './transactionBacklog/transactionBacklog.reducer';
import configurationReducer from './configuration/configuration.reducer';
import neptingReducer from './nepting/nepting.reducer';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const createCombinedReducers = (history: History) => {
  const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['configuration', 'serviceAccount'],
  };

  const appPersistConfig = {
    key: 'appToken',
    storage,
    whitelist: ['token'],
  };

  const rootReducer = combineReducers({
    router: connectRouter(history),
    customer: customerReducer,
    app: persistReducer(appPersistConfig, appReducer),
    hardware: hardwareReducer,
    reload: reloadReducer,
    modal: modalReducer,
    transactionBacklog: transactionBacklogReducer,
    serviceAccount: serviceAccountReducer,
    configuration: configurationReducer,
    nepting: neptingReducer,
  });

  return persistReducer(persistConfig, rootReducer);
};

export type RootState = ReturnType<ReturnType<typeof createCombinedReducers>>;

export const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();

const composeEnhancers =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, no-undef
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  createCombinedReducers(history),
  {},
  composeEnhancers(
    applyMiddleware(
      sagaMiddleware, // for dispatching sagas
      routerMiddleware(history), // for dispatching history actions
    ),
  ),
);

const persistor = persistStore(store);

export default { store, persistor };

// Initialize the middlewares
sagaMiddleware.run(rootSaga);
