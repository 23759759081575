import { createAction } from 'typesafe-actions';
import {
  TransactionBacklogType,
  TransactionBacklogItem,
} from './transactionBacklog.types';

export const addTransactionToValidate = createAction(
  'ADD_TRANSACTION_TO_VALIDATE',
  (
    transactionDraftId: number,
    idempotencyKey: string,
    ticket: string,
  ): TransactionBacklogItem => ({
    transactionDraftId,
    idempotencyKey,
    ticket,
    type: TransactionBacklogType.VALIDATION,
  }),
)();

export const addTransactionToCancel = createAction(
  'ADD_TRANSACTION_TO_CANCEL',
  (
    transactionDraftId: number,
    idempotencyKey: string,
  ): TransactionBacklogItem => ({
    transactionDraftId,
    idempotencyKey,
    type: TransactionBacklogType.CANCELLATION,
  }),
)();

export const markTransactionAsProcessed = createAction(
  'MARK_PAYMENT_AS_PROCESSED',
  (transactionDraftId: number) => ({
    transactionDraftId,
  }),
)();
