import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled';
import { Link } from 'react-router-dom';
import Item from './Item';
import { AccountPaymentType } from '../../../services/innovorder';

const ItemList = styled.ul`
  margin-top: 45px;
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  column-gap: 15px;
  row-gap: 30px;
  padding: 20px;
`;

const ItemContainer = styled(Link)`
  list-style: none;
  width: 100%;
  display: flex;
  color: ${({ theme }): string => theme.color.text};
  justify-content: center;
  align-items: center;
  font-size: ${({ theme }): string => theme.font.size.title};
  text-decoration: none;
  font-weight: 900;
  border: 1px dashed #454545;
  text-align: center;
  border-radius: ${({ theme }): string => theme.radius.main};
  text-transform: uppercase;
  background-color: ${({ theme }): string => theme.color.backgroundhHighlight};
`;

const Help = styled.p`
  margin: 0;
  margin-top: 10px;
  text-align: center;
  font-size: ${({ theme }): string => theme.font.size.text};
  color: ${({ theme }): string => theme.color.textLight};
`;

const Message = styled.h1`
  margin: 0;
  text-align: center;
  font-weight: 900;
  text-transform: uppercase;
  font-size: ${({ theme }): string => theme.font.size.text};
  color: ${({ theme }): string => theme.color.text};
`;

const MessageWarning = styled.h1`
  margin: 0;
  text-align: center;
  font-weight: 900;
  text-transform: uppercase;
  font-size: ${({ theme }): string => theme.font.size.text};
  color: ${({ theme }): string => theme.color.primary};
`;
const amounts = [500, 1000, 2000, 5000];

interface Props {
  accountPaymentType: AccountPaymentType | undefined;
}
const AmountSelector: React.FunctionComponent<Props> = ({
  accountPaymentType,
}) => (
  <>
    {accountPaymentType === AccountPaymentType.POSTPAYMENT ? (
      <MessageWarning>
        <FormattedMessage id={'reload.cannotRecharge'} />
      </MessageWarning>
    ) : (
      <Message>
        <FormattedMessage id={'reload.mainCTA'} />
      </Message>
    )}
    <Help>
      {accountPaymentType === AccountPaymentType.POSTPAYMENT ? null : (
        <FormattedMessage id="reload.mainCTASubtitle" />
      )}
    </Help>
    <ItemList>
      {amounts.map((item: number) => (
        <Item
          amount={item}
          key={item}
          isPostPayment={accountPaymentType === AccountPaymentType.POSTPAYMENT}
        />
      ))}
      {accountPaymentType === AccountPaymentType.POSTPAYMENT ? null : (
        <ItemContainer to={'reload-custom'}>
          <FormattedMessage id="reload.customAmount" />
        </ItemContainer>
      )}
    </ItemList>
  </>
);

export default AmountSelector;
