import React from 'react';
import styled from '@emotion/styled/macro';
import Animation from 'components/Animation';

const ICON_HEIGHT = '300px';

const IconContainer = styled.div`
  display: flex;
  flex-basis: ${ICON_HEIGHT};
  flex-grow: 1;
  flex-shrink: 0;
  justify-content: center;
`;

const Icon = styled.img`
  height: ${ICON_HEIGHT};
  align-self: center;
`;

const CenteredAnimation = styled(Animation)`
  height: ${ICON_HEIGHT};
  align-self: center;
`;

interface Props {
  src?: string;
  alt?: string;
  animation?: object;
}

const CenteredIcon: React.FunctionComponent<Props> = ({
  src,
  alt,
  animation,
}) => (
  <IconContainer>
    {animation ? (
      <CenteredAnimation src={animation} />
    ) : (
      <Icon src={src} alt={alt} />
    )}
  </IconContainer>
);

export default CenteredIcon;
