import React from 'react';
import { Redirect, Route, Switch } from 'react-router';

interface Props {
  children: React.ReactNode;
  component?: React.ComponentType<{}>;
  isActive: boolean;
  redirect?: string;
}
const Gate: React.FunctionComponent<Props> = ({
  redirect,
  component,
  isActive,
  children,
}) => {
  if (isActive) {
    return (
      <Route>
        <Switch>{children}</Switch>
      </Route>
    );
  }
  if (component !== undefined) {
    const Component = component;
    return (
      <Route>
        <Switch>
          <Component />
        </Switch>
      </Route>
    );
  }
  if (redirect !== undefined) {
    return (
      <Route>
        <Redirect to={redirect} />
      </Route>
    );
  }
  throw new Error('Gate requires either component or redirect props');
};
export default Gate;
