import { createReducer, ActionType } from 'typesafe-actions';
import * as configurationActions from './configuration.actions';
import { ContactlessReaderOutputFormat } from './configuration.types';

export type ConfigurationActions = ActionType<typeof configurationActions>;

const defaultState = {
  contactlessReaderOutputFormat: ContactlessReaderOutputFormat.HEX,
  neptingMerchantId: null,
  // merchantId: '15201',
};

export type ConfigurationState = {
  contactlessReaderOutputFormat: ContactlessReaderOutputFormat;
  neptingMerchantId: string | null;
};

const configurationReducer = createReducer<
  ConfigurationState,
  ConfigurationActions
>(defaultState)
  .handleAction(
    configurationActions.setContactlessReaderOutputFormat,
    (state, action) => ({
      ...state,
      contactlessReaderOutputFormat: action.payload.value,
    }),
  )
  .handleAction(configurationActions.setNeptingMerchantId, (state, action) => ({
    ...state,
    neptingMerchantId: action.payload.value,
  }));

export default configurationReducer;
