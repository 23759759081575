import { connect } from 'react-redux';
import { getCustomerBalance } from 'redux/customer/customer.selectors';
import { RootState } from 'redux/store';
import PaymentValidated from './PaymentValidated.page';

const mapStateToProps = (state: RootState): { balance: number | void } => ({
  balance: getCustomerBalance(state),
});

export default connect(mapStateToProps)(PaymentValidated);
