import { RootState } from '../store';
import { ModalState, ModalContent } from './modal.reducer';

export const getModal = (state: RootState): ModalState => state.modal;

export const isModalOpen = (state: RootState): boolean =>
  getModal(state).isOpen;

export const getModalContent = (state: RootState): ModalContent =>
  getModal(state).content;

export const getModalContentId = (state: RootState): string =>
  getModalContent(state).messageId;

export const getModalCtaId = (state: RootState): string =>
  getModalContent(state).ctaId;

export const getComponent = (
  state: RootState,
): React.FunctionComponent<{ onClose: () => unknown }> | undefined =>
  getModal(state).component;
