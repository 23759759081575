export enum TransactionBacklogType {
  VALIDATION,
  CANCELLATION,
}

export type TransactionBacklogItem = (
  | {
      type: TransactionBacklogType.VALIDATION;
      ticket: string;
    }
  | {
      type: TransactionBacklogType.CANCELLATION;
    }
) & { transactionDraftId: number; idempotencyKey: string };
