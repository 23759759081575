import React from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import IconPage from 'components/IconPage';
import styled from 'styled';
import validation from './validation.svg';

interface Props {
  balance: number | void;
}

const Balance = styled.div`
  color: ${({ theme }): string => theme.color.primary};
  font-size: ${({ theme }): string => theme.font.size.big};
`;

const Bold = styled.p`
  font-weight: 900;
`;

const PaymentValidated: React.FunctionComponent<Props> = ({
  balance = NaN,
}) => (
  <IconPage icon={validation} iconAlt="Insert your CB" canDisconnect>
    <FormattedMessage
      id="PaymentValidated.title"
      values={{
        b: function bold(...chunks: React.ReactNode[]): React.ReactNode {
          return <Bold>{chunks}</Bold>;
        },
        balance: (
          <Balance>
            <FormattedNumber
              style="currency"
              currency="EUR"
              minimumFractionDigits={0}
              value={balance / 100}
            />
          </Balance>
        ),
      }}
    />
  </IconPage>
);

export default PaymentValidated;
