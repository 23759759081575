import React from 'react';
import { FormattedMessage } from 'react-intl';
import IconPage from 'components/IconPage';
import loader from './loader.json';

const GenericError: React.FunctionComponent<{}> = () => (
  <IconPage animation={loader}>
    <FormattedMessage id="PaymentCheck.title" />
  </IconPage>
);

export default GenericError;
