const en = {
  'login.product': 'Ewallet kiosk reloader',
  'login.title': 'Identify yourself',
  'login.email': 'Mail',
  'login.password': 'Password',
  'login.login': 'Login',
  'config.start': 'Launch kiosk',
  'config.logout': 'Logout',
  'config.reload': 'Reload',
  'config.printers': 'Printers',
  'config.printerName': 'model name : ',
  'config.target': 'USB port : ',
  'config.contactlessReaderOutputFormat': 'Contactless Reader output format',
  'config.neptingMerchantId': 'Nepting Merchant id',
  'config.model': 'Modèle : ',
  'config.serialNumber': 'Numéro de série : ',
  'adminPopup.code': 'Enter admin password',
  'home.welcome': 'Touch the screen to begin',
  'home.unavailable': 'Not available for now',
  'header.logout': 'Log out',
  'header.back': 'Back',
  'badge.mainCTA': 'Present your badge',
  'badge.error': "We're sorry, we don't have access to your badge.",
  'reload.greetings': 'Hello {name}, your current balance is',
  'reload.mainCTA': 'Reload your badge',
  'reload.cannotRecharge':
    'you cannot charge your badge as you are identified as a postpayment account',
  'reload.mainCTASubtitle': 'Select an amount',
  'reload.next': 'Reload',
  'reload.customAmount': 'Other',
  'customAmount.credit': 'Credit my badge of',
  'customAmount.amountError': 'the reload amount should be between €1 and €400',
  'customAmount.amountErrorCta': 'Choose another amount',
  'ticket.kiosk': 'Terminal ID',
  'ticket.title': 'EWALLET BALANCE RELOAD',
  'ticket.badeNumber': 'swipe card number',
  'ticket.oldBalance': 'Previous balance',
  'ticket.monetiqueHelp': 'E-payment on account',
  'ticket.total': 'Total account operation',
  'ticket.transactionId': 'Transaction number',
  'ticket.newBalance': 'New balance',
  'ticket.address': 'Address',
  'ticket.printedAt': 'Printed on',
  'ticket.printId': 'Print number',
  'PaymentInProcess.title': 'Payment in process',
  'unkown.nepting.error':
    'A problem between the terminal and the kiosk prevented the payment from being proceed',
  'PaymentWaiting.title': 'Payment proceeding …',
  'PaymentCheck.title': 'Verifying hardware …',
  'PaymentValidated.title':
    'Payment successfull. Your new balance is {balance}',
  'GenericError.title': 'An error occurred.',
  'maintenance.message': 'Kiosk in maintenance',
  'error.transaction-validation-failed.title':
    "Une erreur s'est produite sur la mise à jour de votre solde. Veuillez conserver votre ticket pour preuve de paiement.",
};

const fr = {
  'login.product': 'Rechargement E-wallet',
  'login.title': 'Identifiez-vous',
  'login.email': 'E-mail',
  'login.password': 'Mot de passe',
  'login.login': "S'identifier",
  'config.start': 'Lancer la borne',
  'config.logout': 'Déconnexion',
  'config.reload': 'Recharger',
  'config.neptingMerchantId': 'Identifiant nepting',
  'config.neptingTerminal': 'Terminal de paiement',
  'config.printers': 'Imprimantes',
  'config.printerName': 'Nom du model : ',
  'config.target': 'Port USB : ',
  'config.model': 'Modèle : ',
  'config.serialNumber': 'Numéro de série : ',
  'config.contactlessReaderOutputFormat':
    'Format de sortie du lecteur de badge',
  'adminPopup.code': 'Entrer votre code admin',
  'home.welcome': 'Toucher pour recharger votre badge',
  'home.unavailable': 'Cette borne est actuellement non disponible',
  'header.logout': 'Déconnexion',
  'header.back': 'Retour',
  'badge.mainCTA': 'Passez votre badge devant le capteur situé sur la borne',
  'badge.error': 'Veuillez nous excuser, nous ne reconnaissons pas votre badge',
  'reload.greetings': 'Bonjour {name}, votre solde actuel est de',
  'reload.mainCTA': 'rechargez votre badge',
  'reload.cannotRecharge':
    'Vous ne pouvez pas recharger votre badge car votre compte est en post-paiement',
  'reload.mainCTASubtitle': 'Sélectionnez un montant',
  'reload.next': 'Recharger',
  'reload.customAmount': 'Autre',
  'customAmount.credit': 'Créditer mon compte de',
  'customAmount.amountError':
    'Votre montant sélectionné doit être compris entre 1€ et 400€',
  'customAmount.amountErrorCta': 'Saisir un autre montant',
  'ticket.kiosk': 'Borne',
  'ticket.title': 'RECHARGE SOLDE BADGE',
  'ticket.badeNumber': 'N° de badge',
  'ticket.oldBalance': 'Ancien Solde',
  'ticket.monetiqueHelp': 'Versement compte monétique',
  'ticket.total': 'Total opération sur compte',
  'ticket.transactionId': 'N° de transaction',
  'ticket.newBalance': 'Nouveau Solde',
  'ticket.address': 'Adresse',
  'ticket.printedAt': 'Imprimé le',
  'ticket.printId': 'Impression N°',
  'PaymentInProcess.title':
    'Veuillez suivre les instructions de paiement affichées sur le terminal',
  'unkown.nepting.error':
    'un probleme entre la borne est le terminal a empeché votre paiement',
  'PaymentWaiting.title': 'Paiement en cours…',
  'PaymentCheck.title': 'Verification du materiel en cours…',
  'PaymentValidated.title':
    "Merci d'avoir rechargé votre badge. <b>Votre nouveau solde est de</b> {balance}",
  'GenericError.title': 'Une erreur est survenue.',
  'maintenance.message': 'Cette borne est actuellement en maintenance',
  'error.transaction-validation-failed.title':
    "Une erreur s'est produite sur la mise à jour de votre solde. Veuillez conserver votre ticket pour preuve de paiement.",
};

export default {
  en,
  fr,
};

export enum SupportedLanguage {
  'fr' = 'fr',
  'en' = 'en',
}

export function getLocale(): SupportedLanguage {
  const locale: string =
    /* global navigator */
    (navigator.languages && navigator.languages[0]) || navigator.language;

  switch (locale) {
    case 'fr':
    case 'fr-FR':
      return SupportedLanguage.fr;
    case 'en':
    case 'en-GB':
      return SupportedLanguage.en;
    default:
      return SupportedLanguage.fr;
  }
}
