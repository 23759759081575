import React, { useEffect, useRef, MutableRefObject } from 'react';
import Lottie from 'lottie-web';

interface Props {
  src: object;
  className?: string;
}

const Animation: React.FunctionComponent<Props> = ({ src, className }) => {
  const animationTarget: MutableRefObject<HTMLDivElement | null> = useRef(null);
  useEffect(() => {
    if (animationTarget.current !== null) {
      Lottie.loadAnimation({
        container: animationTarget.current,
        animationData: src,
        renderer: 'svg',
        loop: true,
      });
    }
  }, ['src']);

  return <div ref={animationTarget} className={className} />;
};

export default Animation;
