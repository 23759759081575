import { createReducer, ActionType } from 'typesafe-actions';
import * as transactionBacklogActions from './transactionBacklog.actions';
import { TransactionBacklogItem } from './transactionBacklog.types';

export type TransactionBacklogActions = ActionType<
  typeof transactionBacklogActions
>;

export type transactionBacklogState = Array<TransactionBacklogItem>;

const defaultState: transactionBacklogState = [];

const transactionBacklogReducer = createReducer<
  transactionBacklogState,
  TransactionBacklogActions
>(defaultState)
  .handleAction(
    transactionBacklogActions.markTransactionAsProcessed,
    (state, action) =>
      state.filter(
        ({ transactionDraftId }) =>
          transactionDraftId !== action.payload.transactionDraftId,
      ),
  )
  .handleAction(
    transactionBacklogActions.addTransactionToValidate,
    (state, { payload }) => [...state, payload],
  )
  .handleAction(
    transactionBacklogActions.addTransactionToCancel,
    (state, { payload }) => [...state, payload],
  );

export default transactionBacklogReducer;
