import { BRIDGE_MESSAGE } from 'services/webBridge/webBridge.types';

export const humanizeBridgeMessage = (message: BRIDGE_MESSAGE): string => {
  switch (message) {
    case BRIDGE_MESSAGE.PING:
      return 'Ping ? 🏓';
    case BRIDGE_MESSAGE.PONG:
      return 'Pong ✅';
    case BRIDGE_MESSAGE.NEPTING_PINPAD_INITIALIZE:
      return 'Init TPE 📲';
    case BRIDGE_MESSAGE.NEPTING_PINPAD_GET_TERMINAL_INFORMATION:
      return 'Get TPE info ?';
    case BRIDGE_MESSAGE.NEPTING_PINPAD_RESPONSE:
      return 'TPE response 💬';
    case BRIDGE_MESSAGE.NEPTING_PINPAD_START_TRANSACTION:
      return 'Start transaction 💳';

    default:
      return 'message';
  }
};
