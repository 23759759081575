import { connect } from 'react-redux';
import { RootState } from 'redux/store';
import { isWebBridgeConnected } from 'redux/hardware/hardware.selectors';
import Gate from '../Gate';

const mapStateToProps = (state: RootState): { isActive: boolean } => ({
  isActive: isWebBridgeConnected(state),
});

export default connect(mapStateToProps)(Gate);
