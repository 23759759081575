import React from 'react';
import styled from 'styled';
import { motion } from 'framer-motion';

interface Props {
  onPress: (key: string) => unknown;
}

const Container = styled.div`
  display: flex;
  justify-content: center;
`;
const Pad = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;
const Key = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 100px;
`;
const KeyDecoration = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  height: 80px;
  width: 80px;
  border: 1px solid black;
  color: ${({ theme }): string => theme.color.text};
  font-size: ${({ theme }): string => theme.font.size.title};
`;

const keys = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0];

const AdminPopupPad: React.FunctionComponent<Props> = ({ onPress }) => (
  <Container>
    <Pad>
      {keys.map(key => (
        <Key onTouchStart={(): unknown => onPress(key.toString())} key={key}>
          <KeyDecoration whileTap={{ scale: 0.8, backgroundColor: '#ddd' }}>
            {key}
          </KeyDecoration>
        </Key>
      ))}
    </Pad>
  </Container>
);

export default AdminPopupPad;
