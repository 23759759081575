import { connect } from 'react-redux';
import { RootState } from 'redux/store';
import { isTransactionBacklogEmpty } from 'redux/transactionBacklog/transactionBacklog.selectors';
import Gate from 'components/Gate';

const mapStateToProps = (state: RootState): { isActive: boolean } => ({
  isActive: isTransactionBacklogEmpty(state),
});

export default connect(mapStateToProps)(Gate);
