const errors = {
  generic: {
    en: 'Oups, something went wrong.. ',
    fr: "Oups, Quelque chose s'est mal passé..",
  },
  admin_password: {
    en: 'Wrong code',
    fr: 'Code erroné',
  },
};

export default errors;
