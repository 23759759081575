import { RootState } from '../store';
import { ReloadState } from './reload.reducer';

export const getReload = (state: RootState): ReloadState => state.reload;
export const getReloadAmount = (state: RootState): number | undefined =>
  getReload(state).reloadAmount;
export const isAmountDefined = (state: RootState): boolean =>
  getReloadAmount(state) !== undefined;
export const hasAmount = (state: RootState): boolean => {
  const amount: number | false = getReloadAmount(state) || false;
  return amount ? amount > 0 : false;
};
export const isAmountSelected = (state: RootState, amount: number): boolean =>
  getReloadAmount(state) === amount;
