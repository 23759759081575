import { connect } from 'react-redux';
import { RootState } from 'redux/store';
import Gate from 'components/Gate';
import { getToken } from 'redux/app/app.selectors';

const mapStateToProps = (state: RootState): { isActive: boolean } => ({
  isActive: getToken(state) !== undefined,
});

export default connect(mapStateToProps)(Gate);
