import React from 'react';
import styled from 'styled';
import { FormattedMessage } from 'react-intl';

import CenteredIcon from 'components/CenteredIcon';
import Header from 'components/Header';
import cross from './cross.svg';

interface Props {
  errorMessage: string | null;
  startNeptingPayment: () => void;
}

const RetryButton = styled.button`
  position: absolute;
  bottom: 0;
  height: 12%;
  width: 100%;
  font-weight: 900;
  font-family: ${({ theme }): string => theme.font.family}, sans-serif;
  background-color: ${({ theme }): string => theme.color.primary};
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: ${({ theme }): string => theme.color.textOnPrimary};
  font-size: ${({ theme }): string => theme.font.size.title};
  text-transform: uppercase;
  outline: none;
  border: none;
`;

const Background = styled.div`
  text-align: center;
  background-color: ${({ theme }): string => theme.color.background};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  color: ${({ theme }): string => theme.color.text};
`;

const Container = styled.div`
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${({ theme }): string => theme.color.text};
`;

const TransparentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 600px 0 0;
  max-width: 700px;
`;

const PageTitle = styled.h1`
  font-weight: 900;
  font-size: ${({ theme }): string => theme.font.size.title};
  margin: 0;
`;

const PaymentInProcess: React.FunctionComponent<Props> = ({
  errorMessage,
  startNeptingPayment,
}) => (
  <Background>
    <Header />
    <Container>
      <TransparentContainer>
        <CenteredIcon src={cross} alt={'Error with your pay'} />
        <PageTitle>
          {errorMessage ? (
            <p>{errorMessage}</p>
          ) : (
            <FormattedMessage id="unkown.nepting.error" />
          )}
        </PageTitle>
      </TransparentContainer>
      <RetryButton onClick={(): unknown => startNeptingPayment()}>
        <FormattedMessage id="reload.next" />
      </RetryButton>
    </Container>
  </Background>
);

export default PaymentInProcess;
