import { createReducer, ActionType } from 'typesafe-actions';
import { Customer } from 'services/innovorder';
import * as customerActions from './customer.actions';

export type CustomerActions = ActionType<typeof customerActions>;

const defaultState: CustomerState = {
  customer: null,
  error: null,
};

export type CustomerState = {
  customer: null | Customer;
  error: null | string;
};

const customerReducer = createReducer<CustomerState, CustomerActions>(
  defaultState,
)
  .handleAction(
    customerActions.customerUpdated,
    (state, { payload: customer }) => ({ customer, error: null }),
  )
  .handleAction(customerActions.customerFailedToAuthenticate, state => ({
    ...state,
    error: 'badge.error',
  }))
  .handleAction(customerActions.disconnectCustomer, () => defaultState);

export default customerReducer;
