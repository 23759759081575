import { takeLatest, call, put, select, delay } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { createAuthToken } from 'services/innovorder';
import { Action } from 'redux';
import { push } from 'connected-react-router';
import { updateServiceAccount } from 'redux/serviceAccount/serviceAccount.actions';
import { closeModal } from 'redux/modal/modal.actions';
import { SupportedLanguage } from 'translation';
import errors from 'translation/errors';
import {
  fetchToken,
  updateToken,
  dispatchAuthError,
  dispatchAdminPasswordError,
  enterCharForAdminPassword,
  resetTypedAdminPassword,
} from './app.actions';
import {
  getAppLocale,
  getTypedAdminPassword,
  getTypedAdminPasswordLength,
  getAdminPassword,
} from './app.selectors';

function* authHandler({ payload }: ReturnType<typeof fetchToken>): Saga {
  const { mail, password } = payload;
  const locale: SupportedLanguage = yield select(getAppLocale);

  try {
    const authInfos: AsyncReturnType<typeof createAuthToken> = yield call(
      createAuthToken,
      mail,
      password,
      locale,
    );
    yield put<Action>(updateToken(authInfos.accessToken));
    yield put<Action>(updateServiceAccount(authInfos.serviceAccount));
    yield put<Action>(push('/config'));
  } catch (error) {
    if (error.response) {
      yield put<Action>(dispatchAuthError(error.response.data.message));
    } else {
      yield put<Action>(dispatchAuthError(errors.generic[locale]));
    }
  }
}

function* adminPasswordHandler(): Saga {
  const adminPassword = yield select(getAdminPassword);
  const locale: SupportedLanguage = yield select(getAppLocale);
  const typedAdminPassword = yield select(getTypedAdminPassword);
  const typedAdminPasswordLength = yield select(getTypedAdminPasswordLength);

  if (typedAdminPasswordLength > 3) {
    if (adminPassword === typedAdminPassword) {
      yield put<Action>(closeModal());
      yield put<Action>(push('/config'));
    } else {
      yield put<Action>(
        dispatchAdminPasswordError(errors.admin_password[locale]),
      );
    }
    yield delay(250);
    yield put<Action>(resetTypedAdminPassword());
  }
}

export function* watchAppActions(): Saga {
  yield takeLatest(getType(fetchToken), authHandler);
  yield takeLatest(getType(enterCharForAdminPassword), adminPasswordHandler);
}
