import React from 'react';
import styled from 'styled';

const CLICK_DELAY = 5 * 1000;

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 200px;
  height: 200px;
`;

interface Props {
  triggerModal: () => unknown;
}

const PopupAdminTrigger: React.FunctionComponent<Props> = ({
  triggerModal,
}) => {
  let clickCount = 0;

  const reset = (): void => {
    clickCount = 0;
  };

  const click = (): void => {
    clickCount += 1;
    if (clickCount === 1) setTimeout(reset, CLICK_DELAY);
    if (clickCount >= 6) {
      reset();
      triggerModal();
    }
  };

  return <Container onClick={click} data-testid="popup-admin-trigger" />;
};

export default PopupAdminTrigger;
