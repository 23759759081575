import React, { useState } from 'react';
import styled from 'styled';
import { FormattedMessage, IntlShape } from 'react-intl';
import Button from 'components/Button';

const Container = styled.div`
  background-color: ${({ theme }): string => theme.color.background};
  min-height: 100vh;
`;
const Header = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 250px;
`;
const Id = styled.p`
  position: absolute;
  top: 10%;
  ${({ theme }): string => theme.font.size.inputs};
  color: ${({ theme }): string => theme.color.textLight};
  left: 3%;
  letter-spacing: 2px;
`;
const Logo = styled.img`
  max-width: 190px;
  margin-bottom: 25px;
`;
const ProductTitle = styled.h2`
  color: ${({ theme }): string => theme.color.textLight};
  font-size: ${({ theme }): string => theme.font.size.text};
  letter-spacing: 5px;
  text-transform: uppercase;
`;
const Center = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const FormContainer = styled.form`
  padding: 25px;
  box-shadow: 12px 12px 70px rgba(0, 0, 0, 0.08);
  border-radius: ${({ theme }): string => theme.radius.main};
  background-color: ${({ theme }): string => theme.color.backgroundhHighlight};
  width: 500px;
`;
const FormTitle = styled.div`
  padding-top: 10px;
  padding-bottom: 30px;
  text-align: center;
  font-size: ${({ theme }): string => theme.font.size.text};
`;
const Input = styled.input`
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme }): string => theme.color.inputs};
  border-radius: ${({ theme }): string => theme.radius.main};
  width: 100%;
  height: 50px;
  margin-bottom: 30px;
  padding: 18px 15px;
  font-size: ${({ theme }): string => theme.font.size.inputs};
`;

const ErrorMessage = styled.p`
  color: ${({ theme }): string => theme.color.primary};
  margin-top: 10px;
  text-align: center;
`;

const btn = { marginTop: '30px', height: '80px' };

interface Props {
  intl: IntlShape;
  fetchToken: ({
    mail,
    password,
  }: {
    mail: string;
    password: string;
  }) => unknown;
  errorMessage: string | undefined;
}

const Login: React.FunctionComponent<Props> = ({
  intl,
  fetchToken,
  errorMessage = false,
}) => {
  const [mail, setMail] = useState('');
  const [password, setPassword] = useState('');

  return (
    <Container>
      <Header>
        <Id>56541020410</Id>
        <Logo src="https://static-dev.innovorder.fr/io/images/logo4.png" />
        <ProductTitle>
          <FormattedMessage id="login.product" />
        </ProductTitle>
      </Header>
      <Center>
        <FormContainer
          onSubmit={(e): void => {
            e.preventDefault();
            fetchToken({ mail, password });
          }}
        >
          <FormTitle>
            <FormattedMessage id="login.title" />
          </FormTitle>
          <Input
            type="text"
            onChange={(event): unknown => setMail(event.target.value)}
            placeholder={intl.formatMessage({ id: 'login.email' })}
          />
          <Input
            type="password"
            onChange={(event): unknown => setPassword(event.target.value)}
            placeholder={intl.formatMessage({ id: 'login.password' })}
          />
          {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
          <Button style={btn} id="login">
            <FormattedMessage id="login.login" />
          </Button>
        </FormContainer>
      </Center>
    </Container>
  );
};

export default Login;
