import { PrinterInfos, PrinterMap, TicketMessage } from './printer.types';

export const extractLastUsbPrinterFromMap = (
  printersMap: PrinterMap,
): PrinterInfos | null => {
  const firstUsbPrinter = Object.entries(printersMap)
    // malheureusement discover() renvoit toutes les imprimantes, même celle qui n'existent plus. On les trient donc par ID (usb/001/027) pour récupérer la derniere en date
    .sort((a, b) => (a[1].deviceId < b[1].deviceId ? 1 : -1))
    .find(([id]) => id.includes('USB'));

  if (firstUsbPrinter) {
    const [, printer] = firstUsbPrinter;
    return printer;
  }

  return null;
};

export const generateEmptyTicketMessage = (
  ticketData: Partial<Omit<TicketMessage, 'printerData'>>,
): Omit<TicketMessage, 'printerData'> => {
  return {
    xmlTicket: '',
    ticket: { config: '' },
    orderData: '',
    isTest: false,
    localeStringMap: {},
    ...ticketData,
  };
};
