/* eslint-disable max-classes-per-file */
import { TransactionDraft } from 'services/innovorder';

export class ReloadError<T> extends Error {
  constructor(public context: T) {
    super();
  }
}

export class TransactionDraftCreationFailed extends ReloadError<void> {}
export class PaymentAbortedException extends ReloadError<{
  transactionDraft: TransactionDraft;
}> {}
export class PaymentValidationFailed extends ReloadError<{
  transactionDraft: TransactionDraft;
  paymentTicket: string;
  transactionId: string;
}> {}
export class PrintTicketFailed extends ReloadError<{}> {}
export class BrandDataRetrievalFailed extends ReloadError<{}> {}
export class IagonaTransactionCreationFailed extends ReloadError<{}> {}
export class IagonaTransactionCommitFailed extends ReloadError<{}> {}
export class PrinterIsOffline extends ReloadError<{}> {}
