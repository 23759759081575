import { RootState } from '../store';
import { ConfigurationState } from './configuration.reducer';
import { ContactlessReaderOutputFormat } from './configuration.types';

export const getConfiguration = (state: RootState): ConfigurationState =>
  state.configuration;

export const getContactlessReaderOutputFormat = (
  state: RootState,
): ContactlessReaderOutputFormat =>
  getConfiguration(state).contactlessReaderOutputFormat;

export const getNeptingMerchantId = (state: RootState): string | null =>
  getConfiguration(state).neptingMerchantId;
