import { TimeoutError } from 'services/webBridge';

export function timeout(ms: number): Promise<unknown> {
  return new Promise((_, reject) =>
    setTimeout(() => reject(new TimeoutError()), ms),
  );
}

export function withTimeout(
  promise: Promise<unknown>,
  ms: number,
): Promise<unknown> {
  return Promise.race([promise, timeout(ms)]);
}
