import { createAction } from 'typesafe-actions';
import { ModalContent } from './modal.reducer';

export const triggerModal = createAction(
  'TRIGGER_MODAL',
  (content: ModalContent) => ({
    content,
  }),
)();

export const triggerModalWithComponent = createAction(
  'TRIGGER_MODAL_WITH_COMPONENT',
  (
    component: React.FunctionComponent<{
      onClose: () => unknown;
    }>,
  ) => ({
    component,
  }),
)();

export const closeModal = createAction('CLOSE_MODAL')();
