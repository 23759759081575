import { connect } from 'react-redux';
import { RootState } from 'redux/store';
import { getAdminPasswordError } from 'redux/app/app.selectors';
import { flushAdminPopup } from 'redux/app/app.actions';
import AdminCodeInput from './AdminCodeInput.component';

const mapStateToProps = (
  state: RootState,
): {
  errorMessage: string | undefined;
} => ({
  errorMessage: getAdminPasswordError(state),
});

const mapDispatchToProps = {
  flushAdminPopup,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminCodeInput);
